<template>
  <div class="navbar app-nav">
    <div class="container">
      <!-- Logo -->
      <div class="logo">
        <router-link  to="/">
          <img src="./../../assets/logo.svg" alt="Foundit" />
        </router-link>
      </div>

      <!-- Nav Items -->
      <nav :class="{'active': active}">
        <!-- closer -->
        <a class="close" @click="toggle(false)">
          <v-icon icon="close"/>
        </a>
        <!-- links -->
        <a @click="route('/faq')" class="link" v-html="strings.core['F.A.Q.']"></a>
        <a @click="route('/pricing')" class="link" v-html="strings.core['Pricing']"></a>
        <a @click="route('/contact')" class="link" v-html="strings.core['Contact']"></a>
        <a @click="route('/cc')" class="link" v-html="strings.core['Unsubscribe']"></a>
        <!-- language select -->
        <lang-select/>
        <!-- login -->
        <a v-if="!access" @click="route('/login')" class="btn bg-primary" slot="nav">{{ strings.core["Login"] }}</a>
        <a v-if="access" @click="route('/dash')" class="btn bg-primary" slot="nav">{{ strings.core["Dashboard"] }}</a>
      </nav>

      <div class="d-flex align-c mobile-actions">
        <!-- language select -->
        <lang-select class="mobile"/>

        <!-- toggle -->
        <a class="toggle" @click="toggle(true)">
          <i class="icon menu">
            <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 20 20" class="svg-fill">
              <rect x="1.06" y="3.35" width="17.87" height="2.02" rx="1.01"></rect><rect x="1.06" y="8.99" width="17.87" height="2.02" rx="1.01"></rect><rect x="1.06" y="14.63" width="17.87" height="2.02" rx="1.01"></rect>
            </svg>
          </i>
        </a>
      </div>

    </div>
  </div>
</template>

<script lang=ts>
import { strings } from '@/strings'
import { ref } from 'vue'
import { router } from '@/router'
import VIcon from '@/components/icons/icon.vue'
import LangSelect from '@/components/ui/lang-select.vue'

export default {
  name: 'Navbar',
  components: { LangSelect, VIcon },
  setup () {
    // mobile nav state
    const active = ref(false)

    // user access reactive property
    const access = ref(false)

    // mobile nav toggle
    const toggle = (state: boolean) => {
      if (state) {
        active.value = true
        document.body.classList.add('nav-open')
      } else {
        active.value = false
        document.body.classList.remove('nav-open')
      }
    }

    // router function
    const route = (path: string) => {
      toggle(false)
      router.push(path)
    }

    return { active, access, strings, toggle, route }
  },
  /*methods: {
    parse (x) {
      if (this.$locale === 'ru') {
        if (x === 'Часто задаваемые вопросы') {
          return 'Часто задаваемые <br>вопросы'
        } else if (x === 'Контактная информация') {
          return 'Контактная <br>информация'
        } else if (x === 'Удалить подписку') {
          return 'Удалить <br>подписку'
        }
      }
      return x
    }
  }*/
}
</script>
