<template>
  <div class="dial-select" v-blur="hide">

    <!-- toggle -->
    <a class="toggle" @click="toggle()">
      <span v-if="!mounted" style="height: 15px; width: 20px; background-color: #d1d1d7; border-radius: 4px; margin-right: 0.75rem"></span>
      <img v-if="mounted" :src="`/img/flags/${selected.code.toLowerCase()}.svg`" :alt="selected.code" />
      <span>{{ selected.dial_code }}</span>
    </a>

    <!-- dropdown -->
    <div v-if="mounted" v-show="active" class="menu">

      <a v-for="(c, i) in countries" :key="i" @click="on_select(c)">
        <dialcode-flag :country="i"/>
        <span>{{ c.dial_code }}</span>
        <span>{{ c.name }}</span>
      </a>

    </div>

  </div>
</template>

<script lang=ts>
import { countries } from '@/utils/countries'
import DialcodeFlag from '@/components/ui/disalcode-flag.vue'
import { get_geo, user_geo } from '@/utils/geo'

export default {
  name: 'DailcodeSelect',
  components: { DialcodeFlag },
  props: {
    value: undefined
  },
  data () {
    return {
      selected: {
        name: 'Italy',
        dial_code: '+39',
        code: 'IT'
      },
      active: false,
      mounted: false
    }
  },
  methods: {
    on_select (country: string) {
      this.selected = country
      this.$emit('change', this.selected)
    },
    toggle () {
      this.active = !this.active
    },
    hide () {
      if (this.active) this.active = false
    },
    set (iso: string) {
      this.selected = countries[iso]
      this.$emit('change', this.selected)
    }
  },
  setup () {
    return {
      countries
    }
  },
  mounted () {
    if (this.value) {
      this.selected = countries[this.value]
      this.$emit('change', this.selected)
    } else {

      if (user_geo) {
        this.selected = countries[user_geo]
        this.$emit('change', this.selected)
      } else {
        get_geo().then((iso2: string) => {
          this.selected = countries[iso2]
          this.$emit('change', this.selected)
        }).catch(e => {
          console.error(e)
        })
      }
    }

    setTimeout(() => {
      this.mounted = true
    }, 100)
  }
}
</script>
