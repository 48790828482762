<script setup lang="ts">
import { strings } from '@/strings'
import { locator, ParsedPhoneNumber } from '@/utils/locator'
import { router } from '@/router'
import PhoneInput from '@/components/ui/phone-input.vue'

const submit = (parsed_number: ParsedPhoneNumber) => {
  // init locator and save parsed number
  locator.init(parsed_number)
  router.push(`/sign-up?d=${encodeURIComponent(parsed_number.dial)}&n=${parsed_number.number}`)
}

// get url arguments
const args = new URLSearchParams(window.location.search)

// get gclid and save if defined
let h1 = strings.core["Slogan"].replace(/^\s*\w+/, '<span class="text-primary">$&</span>')

</script>

<!-- template markdown -->
<template>
  <div class="col-md-6">

    <div class="text-block">
      <h1 class="xyz-in" xyz="fade down-2 duration-8 delay-1" v-html="h1"></h1>

      <p class="lead mt-2 xyz-in text-dark d-flex align-c" xyz="fade down duration-8 delay-3">
        <span class="triangle bg-primary mr-05"></span>
        {{ strings.core['PhoneError'] }}
      </p>
    </div>

    <!-- Tracker Input Field -->
    <phone-input @ready="submit" ref="phone-input" :btn_text="strings.core['Start']" class="mt-15 xyz-in" xyz="fade duration-8 delay-3"/>

    <p class="lead mt-3 xyz-in" xyz="fade duration-8 delay-3">{{ strings.core['Description'] }}</p>

  </div>
</template>
