const languages: { [key: string]: string }  = {
  cs: 'Česky',
  da: 'Dansk',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  latam: 'Español (LATAM)',
  el: 'Ελληνικά',
  fr: 'Français',
  hu: 'Magyar',
  ko: '한국어',
  it: 'Italiano',
  nl: 'Nederlands',
  no: 'Norsk',
  fi: 'Suomalainen',
  pl: 'Polski',
  pt: 'Português',
  ro: 'Românesc',
  sl: 'Slovenščina',
  sv: 'Svenska',
  sk: 'Slovenčina',
  ru: 'русский'
};

const country_iso2: { [key: string]: string }  = {
  cs: 'cz',
  da: 'dk',
  de: 'de',
  en: 'gb',
  es: 'es',
  latam: 'mx',
  el: 'gr',
  fr: 'fr',
  hu: 'hu',
  ko: 'kr',
  it: 'it',
  nl: 'nl',
  no: 'no',
  fi: 'fi',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  sl: 'sl',
  sv: 'sv',
  sk: 'sk',
  ru: 'ru'
}

interface Language {
  iso    : string
  in_url : boolean
  country : string
}

// check if uri contains a language iso2 code
const uri_lang = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1')

// gets current language data
const init_language = (default_lang: string): Language => {
  // language data
  let language: Language = {
    iso     : default_lang,
    in_url  : false,
    country : default_lang
  }

  // attempt to get locale from url
  if (uri_lang !== undefined && uri_lang !== '/' && languages[uri_lang] !== undefined) {
    language.iso = uri_lang
    language.in_url = true
    language.country = uri_lang
  }

  language.country = country_iso2[language.country]

  return language
}

export { init_language, Language, languages, country_iso2 }
