<!-- template markdown -->
<template>
  <a @click="prompt_lang_select" class="lang-select link">
    <img :alt="config.lang.country" :src="`https://hatscripts.github.io/circle-flags/flags/${config.lang.country}.svg`" width="24">
    <span>
      {{ languages[config.lang.iso] }}
    </span>
  </a>
</template>

<script lang="ts">
import { config } from '@/conf'
import LangSelectModal from '@/components/ui/lang-select-modal.vue'
import { modal } from '@/components/main/modal'
import { languages } from '@/utils/language'

export default {
  setup () {

    // display language select modal
    const prompt_lang_select = () => {
      modal.show(LangSelectModal)
    }

    return {
      config,
      languages,
      prompt_lang_select
    }
  }
}
</script>
