<template>
  <div class="showcase section">




    <div class="container">

      <div class="row">

        <div class="col-md-6 mockup">

          <div class="mockup-bg">
            <span xyz="fade delay-1 duration-8 xyz-in">
              <svg id="a" class="text-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1170 2532"><rect width="1170" height="2532" rx="141.28" ry="141.28" style="stroke-width:0px;"/></svg>
              <svg id="b" class="text-secondary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1170 2532"><rect width="1170" height="2532" rx="141.28" ry="141.28" style="stroke-width:0px;"/></svg>
            </span>
          </div>

          <img src="@/assets/mockup-1.png" alt="Locate any phone, anywhere" class="xyz-in" xyz="fade left-5 delay-2 duration-8"/>

         <!-- <div class="alert">
            <div>{{ strings.home.main[18] }}</div>
            <div>{{ strings.home.main[20] }}</div>
          </div>-->
        </div>

        <div class="col-md-6">

          <h2>
            {{ strings.home.main[9] }}
          </h2>

          <ul class="list icons mt-2">
            <li>
              <icon icon="arrowRight"></icon>
              {{ strings.home.main[10] }}
            </li>
            <li>
              <icon icon="arrowRight"></icon>
              {{ strings.home.main[11] }}
            </li>
            <li>
              <icon icon="arrowRight"></icon>
              {{ strings.home.main[12] }}
            </li>
            <li>
              <icon icon="arrowRight"></icon>
              {{ strings.home.main[13] }}
            </li>
            <li>
              <icon icon="arrowRight"></icon>
              {{ strings.home.main[14] }}
            </li>
          </ul>

          <h4 class="mt-4">{{ strings.home.how[1] }}</h4>

          <div class="icon-block mt-2">
            <i class="icon text-teal">
              <svg xmlns="http://www.w3.org/2000/svg" class="svg-fill" viewBox="0 0 24 24" width="100%" height="100%"><path d="M23.707,22.293l-5.946-5.946c1.412-1.725,2.261-3.928,2.261-6.325C20.021,4.508,15.535,.021,10.021,.021S.021,4.508,.021,10.021s4.486,10,10,10c2.398,0,4.601-.849,6.325-2.261l5.946,5.946c.195,.195,.451,.293,.707,.293s.512-.098,.707-.293c.391-.391,.391-1.023,0-1.414ZM2.021,10.021C2.021,5.61,5.61,2.021,10.021,2.021s8,3.589,8,8-3.589,8-8,8S2.021,14.433,2.021,10.021Zm7.979-6.021c-1.336,0-2.592,.521-3.535,1.465-.944,.943-1.465,2.199-1.465,3.535s.521,2.592,1.473,3.543l1.812,1.773c.474,.463,1.094,.693,1.715,.693s1.241-.23,1.714-.693l1.821-1.781c.944-.943,1.465-2.199,1.465-3.535s-.521-2.591-1.465-3.535-2.2-1.465-3.535-1.465Zm2.129,7.113l-1.813,1.773c-.174,.17-.458,.17-.632,0l-1.805-1.766c-.566-.566-.879-1.319-.879-2.121s.312-1.555,.88-2.121c.565-.567,1.318-.879,2.12-.879s1.555,.312,2.121,.879c.566,.566,.879,1.319,.879,2.121s-.313,1.555-.871,2.113Zm-1.129-2.12c0,.552-.448,1-1,1s-1-.448-1-1,.448-1,1-1,1,.448,1,1Z"/></svg>
            </i>
            <div>
              <h5>{{ strings.home.how[4] }}</h5>
              <p class="mt-025">{{ strings.home.how[5] }}</p>
            </div>
          </div>

          <div class="icon-block mt-2">
            <i class="icon text-teal">
              <svg xmlns="http://www.w3.org/2000/svg" class="svg-fill" viewBox="0 0 24 24" width="100%" height="100%"><path d="M20,0H4A4,4,0,0,0,0,4V16a4,4,0,0,0,4,4H6.9l4.451,3.763a1,1,0,0,0,1.292,0L17.1,20H20a4,4,0,0,0,4-4V4A4,4,0,0,0,20,0Zm2,16a2,2,0,0,1-2,2H17.1a2,2,0,0,0-1.291.473L12,21.69,8.193,18.473h0A2,2,0,0,0,6.9,18H4a2,2,0,0,1-2-2V4A2,2,0,0,1,4,2H20a2,2,0,0,1,2,2Z"/><path d="M7,7h5a1,1,0,0,0,0-2H7A1,1,0,0,0,7,7Z"/><path d="M17,9H7a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2Z"/><path d="M17,13H7a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2Z"/></svg>
            </i>
            <div>
              <h5>{{ strings.home.how[6] }}</h5>
              <p class="mt-025">{{ strings.home.how[7] }}</p>
            </div>
          </div>
          <div class="icon-block mt-2">
            <i class="icon text-teal">
              <svg xmlns="http://www.w3.org/2000/svg" class="svg-fill" viewBox="0 0 24 24" width="100%" height="100%"><path d="M12,12A4,4,0,1,0,8,8,4,4,0,0,0,12,12Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,12,6Zm8.66,3.157-.719-.239A8,8,0,0,0,12,0,7.993,7.993,0,0,0,4.086,9.092a5.045,5.045,0,0,0-2.548,1.3A4.946,4.946,0,0,0,0,14v4.075a5.013,5.013,0,0,0,3.6,4.8l2.87.9A4.981,4.981,0,0,0,7.959,24a5.076,5.076,0,0,0,1.355-.186l5.78-1.71a2.987,2.987,0,0,1,1.573,0l2.387.8A4,4,0,0,0,24,19.021V13.872A5.015,5.015,0,0,0,20.66,9.156ZM7.758,3.762a5.987,5.987,0,0,1,8.484,0,6.037,6.037,0,0,1,.011,8.5L12.7,15.717a.992.992,0,0,1-1.389,0L7.758,12.277A6.04,6.04,0,0,1,7.758,3.762ZM22,19.021a1.991,1.991,0,0,1-.764,1.572,1.969,1.969,0,0,1-1.626.395L17.265,20.2a5.023,5.023,0,0,0-2.717-.016L8.764,21.892a3,3,0,0,1-1.694-.029l-2.894-.9A3.013,3.013,0,0,1,2,18.075V14a2.964,2.964,0,0,1,.92-2.163,3.024,3.024,0,0,1,1.669-.806A8.021,8.021,0,0,0,6.354,13.7l3.567,3.453a2.983,2.983,0,0,0,4.174,0l3.563-3.463a7.962,7.962,0,0,0,1.813-2.821l.537.178A3.006,3.006,0,0,1,22,13.872Z"/></svg>
            </i>
            <div>
              <h5>{{ strings.home.how[8] }}</h5>
              <p class="mt-025">{{ strings.home.how[9] }}</p>
            </div>
          </div>

        </div>

      </div>

    </div>
  </div>

</template>

<script>
import { strings } from '@/strings'
import Icon from '@/components/icons/icon'
import IconBlock from '@/components/icons/icon-block'

export default {
  name: 'Showcase',
  components: { Icon, IconBlock },
  setup () {
    return { strings }
  }
}
</script>
