<template>
  <div class="mockup xyz-in" xyz="fade duration-6 delay-2">
    <div class="mockup-bg">
      <svg id="a" class="text-blue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1170 2532"><rect width="1170" height="2532" rx="141.28" ry="141.28" style="stroke-width:0px;"/></svg>
      <svg id="b" class="text-teal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1170 2532"><rect width="1170" height="2532" rx="141.28" ry="141.28" style="stroke-width:0px;"/></svg>
    </div>

    <img src="@/assets/mockup-1.png" alt="Locate any phone, anywhere" class="xyz-in" xyz="left fade duration-6 delay-3" style="--xyz-translate-x: 30%"/>
    <div class="alert xyz-nested xyz-in" xyz="fade down small-5 delay-9 duration-4 ease-out-back">
      <h6 class="text-blue mb-05">
        <img src="@/assets/icon.svg" alt="Foundit">
        {{ strings.home.main[18] }}
      </h6>
      <!--<span class="divider mb-05"/>-->
      <p class="">{{ strings.home.main[20] }}</p>
    </div>
  </div>
</template>

<script lang=ts>
import { strings } from '@/strings'

export default {
  name: 'HeroImage',
  setup () {
    return { strings }
  }
}
</script>

