<template>
  <span class="hero-layer-wrapper xyz-in" xyz="left fade duration-10 delay-2" style="--xyz-translate-x: -5%">
    <svg class="hero-layer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 239.67"><path d="M163.98,85.05c-28.41,15.87,9.33,36.67,26.33,77.33,32.27,77.2-28.67,23.67-62,54-33.35,30.34-96,21.67-106,17.67-33.4-13.36-9.67-52,1.33-72.67,13.41-25.2,2.57-47.6-10.07-62.45C-1.66,81.02-14.47,39.31,40.06,41.95c23.11,1.12,26.24-5.16,41.93-29.24,23.17-35.56,178.67,18.33,82,72.33Z" /></svg>
  </span>
</template>

<script lang=ts>
export default {
  name: 'HeroLayer'
}
</script>
