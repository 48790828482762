<template>
  <div class="lang-select">
    <h4 class="text-primary text-center">Language</h4>
    <div class="text-center items">
      <a v-for="(l, i) in languages" :key="i" :href="'/' + i + $route.path" :class="{'active': config.lang.iso === i}">
        <img :alt="config.lang.country" :src="flag(i)" width="20">
        <span>{{ l }}</span>
      </a>
    </div>
  </div>
</template>

<script lang=ts>
import { config } from '@/conf'
import { country_iso2, languages } from '@/utils/language'

export default {
  name: 'LangSelectModal',
  setup () {
    const flag = (l: string) => {
      // l = l === 'en' ? 'gb' : l === 'cs' ? 'cz' : l
      return `https://hatscripts.github.io/circle-flags/flags/${country_iso2[l]}.svg`
    }

    return {
      config, languages, flag
    }
  }
}
</script>
