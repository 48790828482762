<template>
  <div class="features section">
    <div class="container">
      <div class="row">

        <!-- 1 -->
        <div class="col-md-4 d-flex-col align-c text-center">
          <i class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="svg-fill" viewBox="0 0 24 24" width="100%" height="100%"><path d="M15.045,0c-.027-.001-6.063-.001-6.09,0-2.736,.024-4.955,2.258-4.955,4.999v14c0,2.757,2.243,5,5,5h6c2.757,0,5-2.243,5-5V5C20,2.258,17.781,.025,15.045,0Zm2.955,18.999c0,1.654-1.346,3-3,3h-6c-1.654,0-3-1.346-3-3V5c0-1.453,1.038-2.667,2.411-2.942l.694,1.389c.169,.339,.516,.553,.895,.553h4c.379,0,.725-.214,.895-.553l.694-1.389c1.373,.274,2.411,1.489,2.411,2.942v14Zm-5,1h-2c-.552,0-1-.448-1-1h0c0-.552,.448-1,1-1h2c.552,0,1,.448,1,1h0c0,.552-.448,1-1,1Z"/></svg>
          </i>
          <h4 class="mb-1">{{ strings.home.main[3] }}</h4>
          <p>{{ strings.home.main[4] }}</p>
        </div>

        <!-- 2 -->
        <div class="col-md-4 d-flex-col align-c text-center">
          <i class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="svg-fill" viewBox="0 0 24 24" width="100%" height="100%"><path d="M12,10c1.1,0,2,.9,2,2s-.9,2-2,2-2-.9-2-2,.9-2,2-2Zm8.49,10.49c4.68-4.68,4.68-12.29,0-16.97-.39-.39-1.02-.39-1.41,0s-.39,1.02,0,1.41c3.9,3.9,3.9,10.24,0,14.14-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29Zm-3.54-3.54c1.32-1.32,2.05-3.08,2.05-4.95s-.73-3.63-2.05-4.95c-.39-.39-1.02-.39-1.41,0s-.39,1.02,0,1.41c.94,.94,1.46,2.2,1.46,3.54s-.52,2.59-1.46,3.54c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29Zm-12.02,3.54c.39-.39,.39-1.02,0-1.41-3.9-3.9-3.9-10.24,0-14.14,.39-.39,.39-1.02,0-1.41s-1.02-.39-1.41,0C-1.16,8.19-1.16,15.81,3.51,20.49c.2,.2,.45,.29,.71,.29s.51-.1,.71-.29Zm3.54-3.54c.39-.39,.39-1.02,0-1.41-1.95-1.95-1.95-5.12,0-7.07,.39-.39,.39-1.02,0-1.41s-1.02-.39-1.41,0c-2.73,2.73-2.73,7.17,0,9.9,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29Z"/></svg>
          </i>
          <h4 class="mb-1">{{ strings.home.main[5] }}</h4>
          <p>{{ strings.home.main[6] }}</p>
        </div>

        <!-- 3 -->
        <div class="col-md-4 d-flex-col align-c text-center">
          <i class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="svg-fill" viewBox="0 0 24 24" width="100%" height="100%"><path d="M2,15c0,.55,.45,1,1,1H11c.55,0,1,.45,1,1s-.45,1-1,1H3c-1.65,0-3-1.35-3-3,0-1.3,.84-2.4,2-2.82V5C2,2.24,4.24,0,7,0h10c2.76,0,5,2.24,5,5v1c0,.55-.45,1-1,1s-1-.45-1-1v-1c0-1.65-1.35-3-3-3H7c-1.65,0-3,1.35-3,3v7h4.5c.27,0,.52,.11,.71,.29l.71,.71h1.09c.55,0,1,.45,1,1s-.45,1-1,1h-1.5c-.27,0-.52-.11-.71-.29l-.71-.71H3c-.55,0-1,.45-1,1Zm22-2v7c0,2.21-1.79,4-4,4h-2c-2.21,0-4-1.79-4-4v-7c0-2.21,1.79-4,4-4h2c2.21,0,4,1.79,4,4Zm-2,0c0-1.1-.9-2-2-2h-2c-1.1,0-2,.9-2,2v7c0,1.1,.9,2,2,2h2c1.1,0,2-.9,2-2v-7Z"/></svg>
          </i>
          <h4 class="mb-1">{{ strings.home.main[7] }}</h4>
          <p>{{  strings.home.main[8] }}</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Icon from '@/components/icons/icon'
import { strings } from '@/strings'

export default {
  name: 'Features',
  components: { Icon },
  setup () {
    return { strings }
  }
}
</script>
